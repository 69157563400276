export const RouterName = {
  auth: {
    login: "/auth/login",
    signup: "",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password/:token",
    maintenance: "/auth/maintenance",
  },
  home: {
    home: "/dashboards",
  },
  carTypes: {
    carTypes: "/car-types",
    createCarTypes: "/car-types/create",
  },
  cars: {
    cars: "/cars",
    createCars: "/cars/create",
  },
  driver: {
    driver: "/drivers",
    createDrivers: "/drivers/create",
  },
  charity: {
    charity: "/charity",
    createCharity: "/charity/create",
  },
  preference: {
    preference: "/preference-type",
    preferenceName: "/preference",
    createPreference: "/preference-type/create",
    createPreferenceName: "/preference/create",
  },
  clientLoyalty: {
    clientLoyalty: "/loyalty",
    createClientLoyalty: "/loyalty/create",
  },
  additionalCharge: {
    additionalCharge: "/additional-charge",
    createAdditionalCharge: "/additional-charge/create",
  },
  client: {
    client: "/client",
    createClient: "/client/create",
  },
  clientAddress: {
    clientAddress: "/address",
    createClientAddress: "/address/create",
  },
  order: {
    order: "/order",
    createOrder: "/order/create",
  },
  dropOff: {
    dropOff: "/drop-off",
    createDropOff: "/drop-off/create",
  },
  orderAcitivty: {
    orderAcitivty: "/activity",
    createOrderAcitivty: "/activity/create",
  },
  zone: {
    zone: "/zone",
    createZone: "/zone/create",
  },
  settings: {
    settings: "/settings",
  },
  admins: {
    admins: "/admins",
    addAdmins: "/admins/create",
  },
  privileges: {
    addPrivileges: "/privileges/create",
    privileges: "/privileges",
  },
  users: {
    users: "/users",
    usersCreate: "/users/create",
  },
};

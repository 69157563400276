// axiosSetup.js
import axios from "axios";
import { handleAxiosError } from "./errorHandler";

export const setupAxiosInterceptors = (dispatch) => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      handleAxiosError(error, dispatch); // Use dispatch here
      return Promise.reject(error);
    }
  );
};

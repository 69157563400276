import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import { RouterName } from "./RouterName";
import { dataLocalStorage, getLocalStorage } from "src/helper/publicFunction";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);
const SettingsPage = Loadable(
  lazy(() => import("src/pages/settings/SettingsPage"))
);
const AdminsPage = Loadable(lazy(() => import("../pages/admins/admins/view")));
const AddAdmin = Loadable(
  lazy(() => import("../pages/admins/admins/AddAdmin"))
);
// const Dashboard = Loadable(lazy(() => import("../pages/dashboard/Dashboard")));

const PrivilegesPage = Loadable(
  lazy(() => import("../pages/admins/privileges/view"))
);
const AddPrivilegs = Loadable(
  lazy(() => import("../pages/admins/privileges/AddPrivilegs"))
);
const UsersList = Loadable(lazy(() => import("src/pages/users/UsersList")));
const AddUser = Loadable(lazy(() => import("src/pages/users/AddUser")));

const CarTypesList = Loadable(
  lazy(() => import("src/pages/carTypes/CarTypesList"))
);

const AddCarTypes = Loadable(
  lazy(() => import("src/pages/carTypes/AddCarTypes"))
);
const CarsList = Loadable(lazy(() => import("src/pages/cars/CarsList")));
const AddCar = Loadable(lazy(() => import("src/pages/cars/AddCar")));
const DriverLists = Loadable(
  lazy(() => import("src/pages/drivers/DriverLists"))
);
const AddDrivers = Loadable(lazy(() => import("src/pages/drivers/AddDrivers")));

const CharityList = Loadable(
  lazy(() => import("src/pages/charity/CharityList"))
);
const AddCharity = Loadable(lazy(() => import("src/pages/charity/AddCharity")));
const PreferenceTypes = Loadable(
  lazy(() => import("src/pages/preferenceType/PreferenceTypes"))
);
const PreferenceName = Loadable(
  lazy(() => import("src/pages/preferenceType/PreferenceName"))
);
const AddPreferenceTypes = Loadable(
  lazy(() => import("src/pages/preferenceType/AddPreferenceTypes"))
);

const AddPreferenceName = Loadable(
  lazy(() => import("src/pages/preferenceType/AddPreferenceName"))
);
const ClientLoyalty = Loadable(
  lazy(() => import("src/pages/client-loyalty/ClientLoyalty"))
);
const AddClientLoyalty = Loadable(
  lazy(() => import("src/pages/client-loyalty/AddClientLoyalty"))
);
const ListAdditionalCharges = Loadable(
  lazy(() => import("src/pages/additional-charges/ListAdditionalCharges"))
);
const AddAdditionalCharges = Loadable(
  lazy(() => import("src/pages/additional-charges/AddAdditionalCharges"))
);
const ClientLists = Loadable(
  lazy(() => import("src/pages/client/ClientLists"))
);

const AddClient = Loadable(lazy(() => import("src/pages/client/AddClient")));
const ClientAddressLists = Loadable(
  lazy(() => import("src/pages/client-address/ClientAddressLists"))
);

const AddClientAddress = Loadable(
  lazy(() => import("src/pages/client-address/AddClientAddress"))
);
const OrderLists = Loadable(lazy(() => import("src/pages/order/OrderLists")));
const AddOrder = Loadable(lazy(() => import("src/pages/order/AddOrder")));
const DropOffLists = Loadable(
  lazy(() => import("src/pages/order-drop-off/DropOffLists"))
);
const AddDropOffLists = Loadable(
  lazy(() => import("src/pages/order-drop-off/AddDropOffLists"))
);

const OrderAcitivtyList = Loadable(
  lazy(() => import("src/pages/order-activity/OrderAcitivtyList"))
);
const AddOrderAcitivty = Loadable(
  lazy(() => import("src/pages/order-activity/AddOrderAcitivty"))
);

const ZoneArea = Loadable(lazy(() => import("src/pages/zone/view")));

const AddZone = Loadable(lazy(() => import("src/pages/zone/AddZone")));

// authentication
const Login = Loadable(
  lazy(() => import("../views/authentication/auth/Login"))
);

const ForgotPassword = Loadable(
  lazy(() => import("../views/authentication/auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("src/views/authentication/auth/ResetPassword"))
);

const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Maintenance = Loadable(
  lazy(() => import("../views/authentication/Maintenance"))
);
let data = getLocalStorage(dataLocalStorage.privileges);

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      // {
      //   path: "/",
      //   exact: true,
      //   element: (
      //     <Navigate
      //       to={
      //         data["13"]?.access_read == 1
      //           ? RouterName.home.home
      //           : data["2"]?.access_read == 1
      //           ? RouterName.users.users
      //           : data["1"]?.access_read == 1
      //           ? RouterName.admins.admins
      //           : data["3"]?.access_read == 1
      //           ? RouterName.flashcard.flashcard
      //           : data["4"]?.access_read == 1
      //           ? RouterName.landing_page.slidershow
      //           : data["5"]?.access_read == 1
      //           ? RouterName.settings.settings
      //           : data["6"]?.access_read == 1
      //           ? RouterName.how_it_work.how_it_work
      //           : data["7"]?.access_read == 1
      //           ? RouterName.activities.category
      //           : data["8"]?.access_read == 1
      //           ? RouterName.topics.topics
      //           : data["9"]?.access_read == 1
      //           ? RouterName.ContactAs.ContactAs
      //           : data["10"]?.access_read == 1
      //           ? RouterName.faqs.faqs
      //           : data["11"]?.access_read == 1
      //           ? RouterName.tips.tips
      //           : data["12"]?.access_read == 1
      //           ? RouterName.admins_logs.admins_logs
      //           : null
      //       }
      //     />
      //   ),
      // },
      data["66feabb80646142336312029"]?.access_read === 1 && {
        path: RouterName.admins.admins,
        exact: true,
        element: <AdminsPage />,
      },
      (data["66feabb80646142336312029"]?.access_write === 1 ||
        data["66feabb80646142336312029"]?.access_edit === 1) && {
        path: `${RouterName.admins.addAdmins}/:id?`,
        exact: true,
        element: <AddAdmin />,
      },
      data["66feabb80646142336312029"]?.access_read === 1 && {
        path: `${RouterName.privileges.privileges}`,
        exact: true,
        element: <PrivilegesPage />,
      },
      (data["66feabb80646142336312029"]?.access_write === 1 ||
        data["66feabb80646142336312029"]?.access_edit === 1) && {
        path: `${RouterName.privileges.addPrivileges}/:id?`,
        exact: true,
        element: <AddPrivilegs />,
      },
      data["66feabcd064614233631202f"]?.access_read === 1 && {
        path: "/users",
        exact: true,
        element: <UsersList />,
      },
      (data["66feabcd064614233631202f"]?.access_write === 1 ||
        data["66feabcd064614233631202f"]?.access_edit === 1) && {
        path: "/users/create/:id?",
        exact: true,
        element: <AddUser />,
      },
      // {
      //   path: "/dashboards",
      //   exact: true,
      //   element: <Dashboard />,
      // },
      data["6707bddc3d7db15d04fd3865"]?.access_read === 1 && {
        path: "/car-types",
        exact: true,
        element: <CarTypesList />,
      },
      (data["6707bddc3d7db15d04fd3865"]?.access_write === 1 ||
        data["6707bddc3d7db15d04fd3865"]?.access_edit === 1) && {
        path: "/car-types/create/:id?",
        exact: true,
        element: <AddCarTypes />,
      },
      data["670789aae6d6e4bd203d4c6f"]?.access_read === 1 && {
        path: "/cars",
        exact: true,
        element: <CarsList />,
      },
      (data["670789aae6d6e4bd203d4c6f"]?.access_write === 1 ||
        data["670789aae6d6e4bd203d4c6f"]?.access_edit === 1) && {
        path: "/cars/create/:id?",
        exact: true,
        element: <AddCar />,
      },
      data["6709225d120c5da076542f8f"]?.access_read === 1 && {
        path: "/drivers",
        exact: true,
        element: <DriverLists />,
      },
      (data["6709225d120c5da076542f8f"]?.access_write === 1 ||
        data["6709225d120c5da076542f8f"]?.access_edit === 1) && {
        path: "/drivers/create/:id?",
        exact: true,
        element: <AddDrivers />,
      },
      data["6710c97a649fbd396948c212"]?.access_read === 1 && {
        path: `/charity`,
        exact: true,
        element: <CharityList />,
      },
      (data["6710c97a649fbd396948c212"]?.access_write === 1 ||
        data["6710c97a649fbd396948c212"]?.access_edit === 1) && {
        path: "/charity/create/:id?",
        exact: true,
        element: <AddCharity />,
      },

      data["6710c9be649fbd396948c234"]?.access_read === 1 && {
        path: "/preference-type",
        exact: true,
        element: <PreferenceTypes />,
      },
      (data["6710c9be649fbd396948c234"]?.access_write === 1 ||
        data["6710c9be649fbd396948c234"]?.access_edit === 1) && {
        path: "/preference-type/create/:id?",
        exact: true,
        element: <AddPreferenceTypes />,
      },

      data["6710c98d649fbd396948c223"]?.access_read === 1 && {
        path: "/preference",
        exact: true,
        element: <PreferenceName />,
      },
      (data["6710c98d649fbd396948c223"]?.access_write === 1 ||
        data["6710c98d649fbd396948c223"]?.access_edit === 1) && {
        path: "/preference/create/:id?",
        exact: true,
        element: <AddPreferenceName />,
      },

      data["6710c9f4649fbd396948c245"]?.access_read === 1 && {
        path: "/loyalty",
        exact: true,
        element: <ClientLoyalty />,
      },
      (data["6710c9f4649fbd396948c245"]?.access_write === 1 ||
        data["6710c9f4649fbd396948c245"]?.access_edit === 1) && {
        path: "/loyalty/create/:id?",
        exact: true,
        element: <AddClientLoyalty />,
      },
      data["6710ca0d649fbd396948c256"]?.access_read === 1 && {
        path: "/additional-charge",
        exact: true,
        element: <ListAdditionalCharges />,
      },
      (data["6710ca0d649fbd396948c256"]?.access_write === 1 ||
        data["6710ca0d649fbd396948c256"]?.access_edit === 1) && {
        path: "/additional-charge/create/:id?",
        exact: true,
        element: <AddAdditionalCharges />,
      },
      data["6710c96e649fbd396948c201"]?.access_read === 1 && {
        path: "/zone",
        exact: true,
        element: <ZoneArea />,
      },
      (data["6710c96e649fbd396948c201"]?.access_write === 1 ||
        data["6710c96e649fbd396948c201"]?.access_edit === 1) && {
        path: "/zone/create/:id?",
        exact: true,
        element: <AddZone />,
      },
      {
        path: "/client",
        exact: true,
        element: <ClientLists />,
      },
      {
        path: "/client/create/:id?",
        exact: true,
        element: <AddClient />,
      },
      {
        path: "/address",
        exact: true,
        element: <ClientAddressLists />,
      },
      {
        path: "/address/create/:id?",
        exact: true,
        element: <AddClientAddress />,
      },
      {
        path: "/order",
        exact: true,
        element: <OrderLists />,
      },
      {
        path: "/order/create/:id?",
        exact: true,
        element: <AddOrder />,
      },
      {
        path: "/drop-off",
        exact: true,
        element: <DropOffLists />,
      },
      {
        path: "/drop-off/create/:id?",
        exact: true,
        element: <AddDropOffLists />,
      },
      {
        path: "/activity",
        exact: true,
        element: <OrderAcitivtyList />,
      },
      {
        path: "/activity/create/:id?",
        exact: true,
        element: <AddOrderAcitivty />,
      },

      data["66feabc6064614233631202c"]?.access_read === 1 && {
        path: "/settings",
        exact: true,
        element: <SettingsPage />,
      },
      { path: "/404", element: <Error /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: RouterName.auth.login, element: <Login /> },
      { path: RouterName.auth.forgotPassword, element: <ForgotPassword /> },
      { path: RouterName.auth.resetPassword, element: <ResetPassword /> },
      { path: RouterName.auth.maintenance, element: <Maintenance /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;

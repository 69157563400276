// errorHandler.js

import { toast } from "react-toastify";
import { Logout } from "src/store/data/user/actionsUser";

export const handleAxiosError = (error, dispatch) => {
  if (error?.response?.data?.message) {
    toast.error(error?.response?.data?.message);
    if (error?.response?.data?.message.includes("Unauthorized")) {
      dispatch(Logout({ navigate: null }));
    }
  }
};
